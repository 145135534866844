/* Tablet portrait */
@media only screen and (min-width: 760px) and (max-width: 1024px) and (orientation: portrait) { 
    .Hero1 {
        background: transparent url('./media/Bilder_Hero/hac_hero_portrait_projektmanagement_1080_1920.jpg') 0% 0% no-repeat padding-box;
        background-position: left center;
    }
    
    .Hero2 {
        background: transparent url('./media/Bilder_Hero/hac_hero_portrait_projektentwicklung_1080_1920.jpg') 0% 0% no-repeat padding-box;
        background-position: left center;
    }
    
    .Hero3 {
        background: transparent url('./media/Bilder_Hero/hac_hero_portrait_bauueberwachung_1080_1920.jpg') 0% 0% no-repeat padding-box;
        background-position: left center;
    }
    
    .Hero4 {
        background: transparent url('./media/Bilder_Hero/hac_hero_portrait_controllingplus_1080_1920.jpg') 0% 0% no-repeat padding-box;
        background-position: left center;
    }

    .CookieContainer {
        background: #FFFFFF;
        position: absolute;
        top: calc((100vh - clamp(400px, 45.43vh, 500px) - 125px)/2);
        left: 20vw;
        height: clamp(430px, 45.43vh, 500px);
        max-width: 768px;
        width: 60vw;
        display: block;
        z-index: 999;
        color: #3C3C3B;
     }
    
     .CookieHeader {
        font-family: var(--unnamed-font-family-poppins);
        font-size: clamp(22px, 1.56vw, 30px);
        line-height: 30px;
        font-weight: 600;
        width: 100%;
        text-align: center;
     }
    
     .CookieContent {
        font-family: var(--unnamed-font-family-poppins);
        font-size: clamp(17px, 1.04vw, 20px);
        line-height: 25px;
        font-weight: var(--unnamed-font-weight-300);
     }
    
     .ButtonStyle {
        height: clamp(40px, 4.35vh, 47px);
        max-width: 212px;
        width: 21.04vw;
        background: var(--unnamed-color-3c3c3b) 0% 0% no-repeat padding-box;
        padding: 0px;
        margin: 0px;
        border: 0px;
        border-radius: 0px;
        box-shadow: none;
        color: var(--unnamed-color-ffffff);
        font-size: clamp(17px, 1.04vw, 20px);
        font-family: var(--unnamed-font-family-poppins);
     }
}

@media only screen and (min-width: 760px) and (max-width: 1024px) {
    .MenuBar {
        position: absolute;
        bottom: 26.4%;
        right: 9.71vw;
        display: flex;
        align-items: center;
    }

    .BigNumberHero {
        font-family: var(--unnamed-font-family-poppins);
        font-weight: 200;
        font-size: clamp(129px, 18.52vh, 200px);
        color: var(--unnamed-color-ffffff);
        line-height: clamp(191px, 23.52vh, 297px);
    }

    .Caption {
        left: 0px;
        top: clamp(85px, 50%, 127px);
        position: absolute;
        font-family: var(--unnamed-font-family-poppins);
        font-weight: var(--unnamed-font-weight-300);
        font-size: clamp(22px, 2.78vh, 30px);
        color: var(--unnamed-color-3c3c3b);
    }

    .Projektmanagement {
        width: 100%;
        height: 2096px;
        position: relative;
    }

    .Projektentwicklung {
        width: 100%;
        height: 1961px;
        position: relative;
        background: var(--unnamed-color-e9e4da) 0% 0% no-repeat padding-box;
    }

    .Bauüberwachung {
        width: 100%;
        height: 2187px;
        position: relative;
    }

    .Controlling {
        width: 100%;
        height: 1802px;
        position: relative;
        background: var(--unnamed-color-e9e4da) 0% 0% no-repeat padding-box;
    }

    .PicProjektmanagement {
        top: 284px;
        width: 100%;
        left: 0px;
        height: 1037px;
        position: absolute;
        background: transparent url('./media/Bilder_Content/hac_projektmanagement_1024_1274.jpg') 0% 0% no-repeat padding-box;
        background-size: cover;
        background-position: center;
        opacity: 1;
        border-radius: 0px 0px 0px 80px;
    }

    .PicProjektentwicklung {
        top: 199px;
        left: 0px;
        width: 100%;
        height: 1038px;
        position: absolute;
        background: transparent url('./media/Bilder_Content/hac_projektentwicklung_1024_1274.jpg') 0% 0% no-repeat padding-box;
        background-size: cover;
        opacity: 1;
        border-radius: 0px 0px 80px 0px;
    }

    .PicBauüberwachung {
        top: 371px;
        left: 0px;
        width: 100%;
        height: 1037px;
        position: absolute;
        background: transparent url('./media/Bilder_Content/hac_bauueberwachung_1024_1274.jpg') 0% 0% no-repeat padding-box;
        background-size: cover;
        background-position: center;
        opacity: 1;
        border-radius: 0px 0px 0px 80px;
    }

    .PicControlling {
        top: 199px;
        left: 0px;
        width: 100%;
        height: 1038px;
        position: absolute;
        background: transparent url('./media/Bilder_Content/hac_controllingplus_1024_1274.jpg') 0% 0% no-repeat padding-box;
        background-size: cover;
        background-position: center;
        opacity: 1;
        border-radius: 0px 0px 80px 0px;
    }

    .PfeilProjektmanagement {
        width: 247px;
        height: 235px;
        background: transparent url('./media/Logos_Grafiken/hac_pfeil_links.png') 0% 0% no-repeat padding-box;
        background-size: contain;
        top: 167px;
        left: calc(8vw + 426px);
        position: absolute;
    }

    .PfeilProjektentwicklung, .PfeilControlling  {
        width: 247px;
        height: 235px;
        background: transparent url('./media/Logos_Grafiken/hac_pfeil_rechts.png') 0% 0% no-repeat padding-box;
        background-size: contain;
        top: 83px;
        right: calc(8vw + 426px);
        position: absolute;
    }
    
    .PfeilBauüberwachung {
        width: 247px;
        height: 235px;        
        background: transparent url('./media/Logos_Grafiken/hac_pfeil_links.png') 0% 0% no-repeat padding-box;
        background-size: contain;
        top: 254px;
        left: calc(8vw + 426px);
        position: absolute;
    }    

    .BoxProjektmanagement {
        top: 85px;
        left: 8vw;
        width: 398px;
        height: 398px;
        background: var(--unnamed-color-e94e1b) 0% 0% no-repeat padding-box;
        position: absolute;
        border-radius: 0px 0px 80px 0px;
    }

    .BoxProjektentwicklung, .BoxControlling {
        right: 8vw;
        width: 398px;
        height: 398px;
        background: var(--unnamed-color-e94e1b) 0% 0% no-repeat padding-box;
        position: absolute;
        border-radius: 0px 0px 0px 80px;
    }

    .BoxBauüberwachung {
        top: 172px;
        left: 8vw;
        width: 398px;
        height: 398px;
        background: var(--unnamed-color-e94e1b) 0% 0% no-repeat padding-box;
        position: relative;
        border-radius: 0px 0px 0px 80px;
    }

    .BigNumber1_3, .BigNumber2_4 {
        top: calc(50% -  297px/2);
        position: absolute;
        font-family: var(--unnamed-font-family-poppins);
        font-weight: 200;
        font-size: var(--unnamed-font-size-200);
        color: var(--unnamed-color-ffffff);
        line-height: 297px;
    }

    .Raute1_3, .Raute2_4 {
        top: calc(66% - 136px/2);
        position: absolute;
        font-style: var(--unnamed-font-style-normal);
        font-variant: normal;
        font-weight: var(--unnamed-font-weight-300);
        font-size: var(--unnamed-font-size-90);
        line-height: 136px;
        font-family: var(--unnamed-font-family-poppins);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-3c3c3b);
    }

    .TextProjektmanagement, .TextProjektentwicklung, .TextBauüberwachung, .TextControlling {
        font-family: var(--unnamed-font-family-poppins);
        font-weight: var(--unnamed-font-weight-300);
        font-size: clamp(65px, 3.7vw, 70px);
        line-height: var(--unnamed-line-spacing-106);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-3c3c3b);
    }

    .TextProjektmanagement {
        top: 1410px;
        left: 81px;
        width: 80.22vw;
        position: relative;
    }

    .TextProjektentwicklung, .TextControlling {
        top: 1330px;
        right: 81px;
        width: 80.58vw;
        position: absolute;
        text-align: right;
    }

    .TextBauüberwachung {
        top: 1501px;
        left: 77px;
        width: 80.22vw;
        position: absolute;
    }

    .TextPosition {
        font-family: var(--unnamed-font-family-poppins);
        font-weight: var(--unnamed-font-weight-300);
        font-size: var(--unnamed-font-size-20);
        line-height: var(--unnamed-line-spacing-45);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-3c3c3b);
        top: 173px;
        left: 7px;
        position: absolute;
    }
    

    .Kontakt {
        width: 100%;
        height: 3117px;
        position: relative;
    }

    .BoxKontakt {
        right: 8vw;
        width: 398px;
        height: 398px;
        background: var(--unnamed-color-e94e1b) 0% 0% no-repeat padding-box;
        top: 1210px;
        position: absolute;
        border-radius: 0px 0px 0px 80px;
        z-index: 1;
    }

    .Planbild {
        top: 172px;
        width: 100%;
        height: 1237px;
        position: absolute;
        background: transparent url('./media/Bilder_Content/hac_desk_kontakt_plan_1920.jpg') 0% 0% no-repeat padding-box;
        background-size: cover;
        background-position: 30%;
    }

    .KontaktText {
        top: 2540px;
        right: 90px;
        width: 392px;
        height: 526px;
        position: absolute;
        text-align: right;
    }

    .InnerKontaktText {
        top: 178px;
        right: 0px;
        position: absolute;
    }

    .Footer {
        width: 100%;
        height: 830px;
        position: relative;
        background: var(--unnamed-color-ac9b73) 0% 0% no-repeat padding-box;
        margin: auto;
    }

    .ExtraPages {
        top: 69px;
        right: 6.35%;
        width: 392px;
        height: 74px;
        position: absolute;
        font-size: var(--unnamed-font-size-20);
        line-height: var(--unnamed-line-spacing-30);
        font-family: var(--unnamed-font-family-poppins);
        font-weight: 600;
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-ffffff);
        text-align: right;
    }

    .LogoGüterstation {
        position: absolute;
        left: -116px;
        top: 409px;
        width: 753px;
        height: 421px;
    }

    .LogoFooter {
        position: absolute;
        top: 172px;
        right: 6.35%;
        width: 260px;
        height: 42px;
    }

    .LogoGVL {
        position: absolute;
        top: 267px;
        right: 6.35%;
        width: 358px;
        height: 57px;
    }

    .MapBild {
        background: transparent url('./media/Bilder_Content/hac_standortkarte_1024_1274_mit_Location.jpg') 0% 0% no-repeat padding-box;
        background-position: center;
        opacity: 1;
        border-radius: 0px 0px 50px 0px;
        position: absolute;
        width: 100%;
        height: 1038px;
        top: 1409px;
    }
}
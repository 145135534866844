/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  max-width: 1920px;
  max-height: 1080px;
  min-height: 500px;
  margin: 0px auto;
  display: block;
  overflow-wrap: break-word; 
  -webkit-nbsp-mode: space; 
  line-break: after-white-space;
}

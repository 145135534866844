@media only screen and (min-width: 1024px){
    .MenuBar {
        position: absolute;
        bottom: 26.4%;
        right: 7.29vw;
        display: flex;
        align-items: center;
    }

    .Projektmanagement, .Bauüberwachung {
        width: 100%;
        height: clamp(calc(806px + min(10.42vw, 200px) + min(10.83vw, 208px)), 85.625vw, 1644px);
        position: relative;
    }

    .Projektentwicklung, .Controlling {
        width: 100%;
        height: clamp(806px, 64.43vw, 1237px);
        position: relative;
        background: var(--unnamed-color-e9e4da) 0% 0% no-repeat padding-box;
    }

    .PicProjektmanagement {
        top: calc(min(10.42vw, 200px) + clamp(260px, 20.73vw, 398px)/2);
        left: min(7.29vw, 140px);
        width: clamp(450px, 35.94vw, 690px);
        height: clamp(676px, 54vw, 1037px);
        position: absolute;
        background: transparent url('./media/Bilder_Content/hac_projektmanagement_1024_1274.jpg') 0% 0% no-repeat padding-box;
        background-size: cover;
        background-position: center;
        opacity: 1;
        border-radius: 0px 0px 0px 80px;
    }

    .PicProjektentwicklung {
        top: calc(clamp(260px, 20.73vw, 398px)/2);
        right: min(7.29vw, 140px);
        width: clamp(450px, 35.94vw, 690px);
        height: clamp(676px, 54vw, 1037px);
        position: absolute;
        background: transparent url('./media/Bilder_Content/hac_projektentwicklung_1024_1274.jpg') 0% 0% no-repeat padding-box;
        background-size: cover;
        opacity: 1;
        border-radius: 0px 0px 80px 0px;
    }

    .PicBauüberwachung {
        top: calc(min(10.42vw, 200px) + clamp(260px, 20.73vw, 398px)/2);
        left: min(7.29vw, 140px);
        width: clamp(450px, 35.94vw, 690px);
        height: clamp(676px, 54vw, 1037px);
        position: absolute;
        background: transparent url('./media/Bilder_Content/hac_bauueberwachung_1024_1274.jpg') 0% 0% no-repeat padding-box;
        background-size: cover;
        background-position: center;
        opacity: 1;
        border-radius: 0px 0px 0px 80px;
    }

    .PicControlling {
        top: calc(clamp(260px, 20.73vw, 398px)/2);
        right: min(7.29vw, 140px);
        width: clamp(450px, 35.94vw, 690px);
        height: clamp(676px, 54vw, 1037px);
        position: absolute;
        background: transparent url('./media/Bilder_Content/hac_controllingplus_1024_1274.jpg') 0% 0% no-repeat padding-box;
        background-size: cover;
        background-position: center;
        opacity: 1;
        border-radius: 0px 0px 80px 0px;
    }

    .PfeilProjektmanagement, .PfeilBauüberwachung {
        width: clamp(240px, 19.17vw, 368px);
        height: clamp(230px, 18.23vw, 350px);
        background: transparent url('./media/Logos_Grafiken/hac_pfeil_rechts.png') 0% 0% no-repeat padding-box;
        background-size: contain;
        background-position: center;
        top: calc(min(10.42vw, 200px) + clamp(260px, 20.73vw, 398px)/2 - clamp(230px, 18.23vw, 350px)/2);
        left: calc(min(7.29vw, 140px) + clamp(450px, 35.94vw, 690px)/4);
        position: absolute;
    }

    .PfeilProjektentwicklung, .PfeilControlling {
        width: clamp(240px, 19.17vw, 368px);
        height: clamp(230px, 18.23vw, 350px);
        background: transparent url('./media/Logos_Grafiken/hac_pfeil_links.png') 0% 0% no-repeat padding-box;
        background-size: contain;
        background-position: center;
        top: calc(clamp(260px, 20.73vw, 398px)/2 - clamp(230px, 18.23vw, 350px)/2);
        right: calc(min(7.29vw, 140px) + clamp(450px, 35.94vw, 690px)/4);
        position: absolute;
    }

    .BoxProjektmanagement, .BoxBauüberwachung {
        width: clamp(260px, 20.73vw, 398px);
        height: clamp(260px, 20.73vw, 398px);
        background: var(--unnamed-color-e94e1b) 0% 0% no-repeat padding-box;
        top: min(10.42vw, 200px);
        left: clamp(450px + 7.29vw, 43.22vw, 830px);
        position: absolute;
        border-radius: 0px 0px 80px 0px;
    }

    .BoxProjektentwicklung, .BoxControlling {
        width: clamp(260px, 20.73vw, 398px);
        height: clamp(260px, 20.73vw, 398px);
        background: var(--unnamed-color-e94e1b) 0% 0% no-repeat padding-box;
        right: clamp(450px + 7.29vw, 43.22vw, 830px);
        position: absolute;
        border-radius: 0px 0px 0px 80px;
    }

    .BigNumber1_3, .BigNumber2_4 {
        top: calc(50% -  min(27.5vh, 297px)/2);
        position: absolute;
        font-family: var(--unnamed-font-family-poppins); 
        font-weight: 200;
        font-size: clamp(130px, 10.41vw, 200px);
        color: var(--unnamed-color-ffffff);
        line-height: clamp(193px, 27.5vh, 297px);
    }
    
    .Raute1_3, .Raute2_4 {
        top: calc(66% - min(12.59vh, 136px)/2);
        position: absolute;
        font-style: var(--unnamed-font-style-normal);
        font-variant: normal;
        font-size: clamp(59px, 4.6875vw, 90px);
        line-height: clamp(89px, 12.59vh, 136px);
        font-family: var(--unnamed-font-family-poppins); 
        font-weight: var(--unnamed-font-weight-300);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-3c3c3b);
    }
    
    .TextProjektmanagement, .TextBauüberwachung {
        font-family: var(--unnamed-font-family-poppins);
        font-weight: var(--unnamed-font-weight-300);
        font-size: clamp(47px, 3.7vw, 70px);
        line-height: min(5.5vw, 106px);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-3c3c3b);
        top: clamp(431px, 34.43vw, 661px);
        left: clamp(560px, 49.22vw, 945px);
        max-width: 838px;
        width: 43.65vw;
        position: absolute;
    }

    .TextProjektentwicklung, .TextControlling {
        font-family: var(--unnamed-font-family-poppins); 
        font-weight: var(--unnamed-font-weight-300);
        font-size: clamp(47px, 3.7vw, 70px);
        line-height: min(5.5vw, 106px);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-3c3c3b);
        top: clamp(314px, 25.05vw, 481px);
        right: clamp(560px, 49.22vw, 949px);
        max-width: 838px;
        width: 43.65vw;
        position: absolute;
        text-align: right;
    }

    .TextPosition {
        font-family: var(--unnamed-font-family-poppins); 
        font-weight: var(--unnamed-font-weight-300);
        font-size: var(--unnamed-font-size-20);
        /* min wert sollte 30 sein - testen wies am tablet aussieht*/
        line-height: clamp(35px, 2.34vw, 45px);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-3c3c3b);
        top: min(9.01vw, 173px);
        left: 7px;
        position: absolute;
    }

    .Kontakt {
        width: 100%;
        height: clamp(calc(min(5.21vw, 100px) + min(64.43vw, 1237px) + 676px + min(10.42vw, 200px)), 134.12vw, 2575px);
        position: relative;
    }

    .BoxKontakt {
        width: clamp(260px, 20.73vw, 398px);
        height: clamp(260px, 20.73vw, 398px);
        background: var(--unnamed-color-e94e1b) 0% 0% no-repeat padding-box;
        top: clamp(100px, 59.32vw, 1139px);
        right: clamp(450px + 7.29vw, 43.22vw, 830px);
        position: absolute;
        border-radius: 0px 0px 0px 80px;
    }

    .Planbild {
        top: min(5.21vw, 100px);
        width: 100%;
        height: min(64.43vw, 1237px);
        position: absolute;
        background: transparent url('./media/Bilder_Content/hac_desk_kontakt_plan_1920.jpg') 0% 0% no-repeat padding-box;
        background-size: cover;
    }

    .KontaktText {
        top: min(84.375vw, 1620px);
        right: clamp(560px, 49.22vw, 949px); 
        width: 392px;
        height: min(27.4vw, 526px);
        position: absolute;
        text-align: right;
    }

    .InnerKontaktText {
        top: 33.84%;
        right: 0px;
        position: absolute;
    }

    .Footer {
        width: 100%;
        height: min(38.75vw, 744px);
        position: relative;
        background: var(--unnamed-color-ac9b73) 0% 0% no-repeat padding-box;
        margin: auto;
    }

    .ExtraPages {
        top: min(17.45vw, 335px);
        right: 6.35%;
        width: 392px;
        height: 98px;
        position: absolute;
        font-size: var(--unnamed-font-size-20);
        line-height: var(--unnamed-line-spacing-30);
        font-family: var(--unnamed-font-family-poppins); 
        font-weight: 600;
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-ffffff);
        text-align: right;
    }

    .LogoGüterstation {
        position: absolute;
        left: -102px;
        bottom: 0px;
        width: 68.5%;
    }

    .LogoFooter {
        position: absolute;
        top: 62.1%;
        right: 6.35%;
        height: clamp(35px, 3.89vh, 42px);
        width: auto;
    }

    .LogoGVL {
        position: absolute;
        top: 74.87%;
        right: 6.35%;
        height: clamp(38px, 5.28vh, 57px);
        width: auto;
    }

    .MapBild {
        background: transparent url('./media/Bilder_Content/hac_standortkarte_1024_1274_mit_Location.jpg') 0% 0% no-repeat padding-box;
        background-size: cover;
        background-position: center;
        opacity: 1;
        border-radius: 0px 0px 50px 0px;
        position: absolute;
        top: calc(clamp(100px, 59.32vw, 1139px) + clamp(260px, 20.73vw, 398px)/2);
        right: min(7.29vw, 140px);
        width: clamp(450px, 35.94vw, 690px);
        height: clamp(676px, 54vw, 1037px);
    }
}